/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Label, Textarea, Input, Button } from "@theme-ui/components"
import { Formik, useField, Form, Field } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { navigate } from "gatsby"
import qs from "qs"

const TextInput = ({ label, locale, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <Box sx={{ mb: 3 }}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Input {...field} {...props} />
      {meta.touched && meta.error ? (
        <Box sx={{ mt: 1, fontSize: 1, color: "danger" }}>{meta.error}</Box>
      ) : null}
    </Box>
  )
}

const MyTextarea = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <Box sx={{ mb: 3 }}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Textarea {...field} {...props} />
      {meta.touched && meta.error ? (
        <Box sx={{ fontSize: 1, color: "danger" }}>{meta.error}</Box>
      ) : null}
    </Box>
  )
}

const ContactForm = ({ locale }) => {
  const t = {
    fi: {
      name: "Nimi",
      email: "Sähköposti",
      message: "Viesti",
      send: "Lähetä",
      wait: "Hetkinen...",
      required: "Pakollinen tieto.",
      checkemail: "Tarkista sähköpostiosoite.",
      maxName: "Nimen enimmäispituus on 30 merkkiä.",
    },
    en: {
      name: "Name",
      email: "Email",
      message: "Message",
      send: "Send",
      wait: "Hold on...",
      required: "Required field.",
      checkemail: "Check email address.",
      maxName: "Maximum length is 30 characters.",
    },
  }

  return (
    <Formik
      initialValues={{
        "bot-field": "",
        "form-name": "yhteys",
        nimi: "",
        sahkoposti: "",
        viesti: "",
      }}
      validationSchema={Yup.object({
        nimi: Yup.string()
          .max(30, t[locale].maxName)
          .required(t[locale].required),
        sahkoposti: Yup.string()
          .email(t[locale].checkemail)
          .required(t[locale].required),
        viesti: Yup.string().required(t[locale].required),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(values, null, 2),
          url: "/",
        }
        try {
          await axios(options)
          navigate(locale === "en" ? "/en/thankyou" : "/kiitos")
        } catch (e) {
          console.log(e.message)
        }
        setSubmitting(false)
      }}
    >
      {props => (
        <Form
          name="yhteys"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          sx={{ maxWidth: 800 }}
        >
          <Field type="hidden" name="form-name" />
          <Field type="hidden" name="bot-field" />
          <TextInput label={t[locale].name} name="nimi" type="text" />
          <TextInput label={t[locale].email} name="sahkoposti" type="email" />
          <MyTextarea label={t[locale].message} name="viesti" rows={4} />
          <Box>
            <Button
              variant="primary"
              type="submit"
              disabled={props.isSubmitting}
            >
              {props.isSubmitting ? t[locale].wait : t[locale].send}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
