/** @jsx jsx */
import { jsx, Box, Styled } from "theme-ui"
import { Link } from "gatsby"

export default ({ blog, locale }) => (
  <Box sx={{ my: 4 }}>
    <Box sx={{ fontSize: 2, mb: 2, color: "#808080" }}>{blog.publishDate}</Box>
    <Styled.h4 as="h3" sx={{ ":not(:first-child)": { mt: 0 }, mb: 1 }}>
      <Styled.a
        as={Link}
        to={locale === "en" ? `/en/blog/${blog.slug}` : `/blogi/${blog.slug}`}
      >
        {blog.title}
      </Styled.a>
    </Styled.h4>
    <Styled.p>
      {blog.body.childMdx.excerpt}{" "}
      <Styled.a
        as={Link}
        to={locale === "en" ? `/en/blog/${blog.slug}` : `/blogi/${blog.slug}`}
      >
        {locale === "en" ? "Read more" : "Lue lisää"} &rarr;
      </Styled.a>
    </Styled.p>
  </Box>
)
