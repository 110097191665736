/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default props => {
  const content = props.content

  return (
    content.copy && (
      <Box>
        <MDXRenderer>{content.copy.childMdx.body}</MDXRenderer>
      </Box>
    )
  )
}
