/** @jsx jsx */
import { jsx, Box, Styled } from "theme-ui"
import { Link } from "gatsby"
import { Button } from "@theme-ui/components"

export default props => {
  const t = {
    fi: {
      title: "Ota yhteyttä",
      description:
        "Haluatko tietää lisää? Me ainakin haluamme kuulla lisää sinun liiketoiminnastasi ja haasteistasi.",
      link: "/yhteystiedot",
      linkText: "Ota yhteyttä",
    },
    en: {
      title: "Contact Us",
      description:
        "Want to know more? At least we want to hear more about your business and challenges.",
      link: "/en/contact",
      linkText: "Contact Us",
    },
  }
  const loc = props.locale
  return (
    <Box>
      <Styled.h2>{t[loc].title}</Styled.h2>
      <Styled.p>{t[loc].description}</Styled.p>
      <Button as={Link} to={t[loc].link}>
        {t[loc].linkText}
      </Button>
    </Box>
  )
}
