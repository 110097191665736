/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

const Accordion = ({ title, children, ...props }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Box
      sx={{
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "primary",
      }}
      {...props}
    >
      <Flex
        sx={{
          cursor: "pointer",
        }}
        onClick={() => setExpanded(expanded ? false : true)}
      >
        <Box
          as={motion.header}
          initial={false}
          sx={{ flex: "auto", fontWeight: "bold", py: 3 }}
          children={title}
        />
        <motion.div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: "none",
            marginTop: 24,
            marginRight: 8,
            transformOrigin: "center center",
            width: 20,
            height: 20,
          }}
          animate={{ transform: expanded ? "rotate(45deg)" : "rotate(90deg)" }}
        >
          <Box
            as="svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="matrix(1,0,0,1,0,0)">
              <path
                d="M 0.75,12h22.5 "
                stroke="#4289AD"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M 12,0.75v22.5"
                stroke="#4289AD"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </Box>
        </motion.div>
      </Flex>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.6, 0.2, 1] }}
          >
            <motion.div
              variants={{ collapsed: { scale: 0.9 }, open: { scale: 1 } }}
              transition={{ duration: 0.15 }}
            >
              <Box
                sx={{
                  pb: 4,
                  maxWidth: 800,
                  "*:first-of-type": { mt: 0 },
                  "*:last-of-type": { mb: 0 },
                }}
                children={children}
              />
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default Accordion
