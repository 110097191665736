/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import LayoutModules from "../components/contentModules"
import Spacer from "../components/spacer"
import ContactForm from "../components/contactForm"
import Container from "../components/container"
import Contact from "../components/contactCTA"

export default ({ data }) => {
  const {
    title,
    slug,
    node_locale,
    contentModules,
    description,
  } = data.contentfulLayout

  return (
    <Layout locale={node_locale}>
      <SEO title={title} description={description} />
      {contentModules[0].__typename !== "ContentfulLayoutHero" && (
        <Spacer size={[6, 7]} />
      )}
      {contentModules && (
        <LayoutModules contentModules={contentModules} locale={node_locale} />
      )}
      {["yhteystiedot", "contact"].includes(slug) ? (
        <>
          <Spacer size={[4, null, 5]} />
          <Container>
            <Styled.h3>Contact Us</Styled.h3>
            <ContactForm locale={node_locale} />
          </Container>
        </>
      ) : (
        <Container>
          <Spacer size={[4, null, 5]} />
          <Contact locale={node_locale} />
        </Container>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulLayout(slug: { eq: $slug }) {
      title
      slug
      node_locale
      description
      contentModules {
        __typename
        ... on ContentfulLayoutHero {
          title: headline {
            childMdx {
              id
              body
            }
          }
          ctaLink
          ctaTitle
          image {
            description
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
        ... on ContentfulLayoutCopy {
          copy {
            childMdx {
              id
              body
            }
          }
        }
        ... on ContentfulLayoutHighlight {
          headline
          copy {
            childMdx {
              id
              body
            }
          }
          ctaLink
          ctaTitle
          image {
            description
            fluid(maxWidth: 800) {
              sizes
              base64
              aspectRatio
              src
              srcSet
            }
          }
        }
        ... on ContentfulJobListing {
          jobListingTitle: title
          jobs {
            title
            description {
              childMdx {
                id
                body
              }
            }
          }
        }
        ... on ContentfulLayoutBlog {
          blogPosts {
            title
            slug
            publishDate
            language
            body {
              childMdx {
                id
                body
                excerpt
              }
            }
            author {
              name
              jobTitle
            }
          }
        }
        ... on ContentfulLayoutMedia {
          images {
            description
            fluid(maxWidth: 512, maxHeight: 512) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
        ... on ContentfulLayoutGrid {
          columns
          contentModules {
            __typename
            ... on ContentfulLayoutCopy {
              copy {
                childMdx {
                  id
                  body
                }
              }
            }
            ... on ContentfulContact {
              name
              jobTitle
              phoneNumber
              email
              summary
              linkedInProfile
              image {
                description
                fluid {
                  sizes
                  base64
                  aspectRatio
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`
