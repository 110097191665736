/** @jsx jsx */
import { jsx, Flex, Box, Styled } from "theme-ui"
import Container from "./container"
import Contact from "./contact"
import Copy from "./copy"
import Hero from "./hero"
import Highlight from "./highlight"
import Spacer from "./spacer"
import Accordion from "./accordion"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import BlogPost from "./blogPost"
import { Link } from "gatsby"

const language = { en: "English", fi: "Finnish" }

export default ({ contentModules, locale }) => {
  let contentMods = []
  let reverse = false
  contentModules.map((mod, i) => {
    switch (mod.__typename) {
      case "ContentfulLayoutHero":
        contentMods.push(
          <Box key={i}>
            <Hero
              image={mod.image}
              title={mod.title}
              ctaTitle={mod.ctaTitle}
              ctaLink={mod.ctaLink}
              locale={locale}
            />
            <Spacer size={[3, 4, 5]} />
          </Box>
        )
        break
      case "ContentfulLayoutHighlight":
        if (
          contentModules[i - 1].__typename === "ContentfulLayoutHighlight" &&
          reverse === false
        )
          reverse = true
        else reverse = false
        contentMods.push(
          <Container sx={{ px: [0, 0, 0, 5] }} key={i}>
            <Highlight content={mod} key={i} reverse={reverse} />
            <Spacer size={[2, 3]} />
          </Container>
        )
        break
      case "ContentfulLayoutCopy":
        contentMods.push(
          <Container key={i}>
            <Spacer size={[4, null, 5]} />
            <Copy content={mod} key={i} />
            <Spacer size={[4, null, 5]} />
          </Container>
        )
        break
      case "ContentfulJobListing":
        contentMods.push(
          <Container key={i}>
            <Spacer size={[4, null, 5]} />
            <Styled.h2>{mod.jobListingTitle}</Styled.h2>
            {mod.jobs &&
              mod.jobs.map(job => (
                <Accordion title={job.title}>
                  <MDXRenderer>{job.description.childMdx.body}</MDXRenderer>
                </Accordion>
              ))}
            <Spacer size={[4, null, 5]} />
          </Container>
        )
        break
      case "ContentfulLayoutBlog":
        const title = locale === "en" ? "News" : "Ajankohtaista"
        const moreLinkText =
          locale === "en" ? "More articles" : "Lisää kirjoituksia"
        const posts = mod.blogPosts.filter(
          node => node.language && node.language.includes(language[locale])
        )
        contentMods.push(
          <Container key={i}>
            <Spacer size={[4, null, 5]} />
            <Flex
              sx={{
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Styled.h2 sx={{ mb: 0 }}>{title}</Styled.h2>
              <Styled.a as={Link} to={locale === "en" ? "/en/blog" : "/blogi"}>
                {moreLinkText} &rarr;
              </Styled.a>
            </Flex>
            {posts &&
              posts.map(blog => <BlogPost blog={blog} locale={locale} />)}
            <Spacer size={[4, null, 5]} />
          </Container>
        )
        break
      case "ContentfulLayoutMedia":
        contentMods.push(
          <Container key={i}>
            <Spacer size={[4, null, 5]} />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: [
                  "repeat(2, minmax(80px, 160px))",
                  "repeat(3, minmax(80px, 160px))",
                  "repeat(5, minmax(80px, 160px))",
                ],
                gridAutoRows: "minmax(40px, 80px)",
                gridColumnGap: 5,
                gridRowGap: [1, 2],
                mx: "auto",
                justifyContent: "center",
              }}
            >
              {mod.images &&
                mod.images.map(image => (
                  <Img
                    fluid={image.fluid}
                    sx={{ maxHeight: "100%" }}
                    imgStyle={{ objectFit: "contain" }}
                    alt={image.description}
                  />
                ))}
            </Box>
            <Spacer size={[4, null, 5]} />
          </Container>
        )
        break
      case "ContentfulLayoutGrid":
        let gridMods = []
        mod.contentModules &&
          mod.contentModules.map((gridMod, j) => {
            switch (gridMod.__typename) {
              case "ContentfulLayoutCopy":
                gridMods.push(<Copy content={gridMod} key={j} />)
                break
              case "ContentfulContact":
                gridMods.push(<Contact contact={gridMod} key={j} />)
                break
              default:
                break
            }
          })
        contentMods.push(
          <Container key={i}>
            <Spacer size={[4, null, 5]} />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: [
                  "1fr",
                  null,
                  mod.columns === 4 ? "1fr 1fr" : null,
                  "repeat(" + mod.columns + ", 1fr)",
                ],
                gridGap: [3, null, 4],
              }}
            >
              {gridMods}
            </Box>
            <Spacer size={[4, null, 5]} />
          </Container>
        )
        break
      default:
        break
    }
  })
  return contentMods
}
