/** @jsx jsx */
import { jsx, useThemeUI, Styled, Box, Flex } from "theme-ui"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import { setLightness } from "polished"

export default props => {
  const content = props.content
  const reverse = props.reverse
  const { theme } = useThemeUI()

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", null, "1fr 1fr"],
        gridGap: [0, null, 3],
      }}
    >
      {content.image && (
        <Img
          fluid={content.image.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          sx={{
            order: reverse ? [null, null, 0] : [null, null, 1],
            minHeight: [240],
            maxHeight: ["30vh", null, "none"],
            bg: setLightness(0.25, theme.colors.primary),
            img: {
              mixBlendMode: "screen",
            },
          }}
          alt={content.image.description}
        />
      )}
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          bg: "blueLight",
          p: [4, null, 5],
          order: reverse ? [null, null, 1] : [null, null, 0],
        }}
      >
        {content.headline && <Styled.h3>{content.headline}</Styled.h3>}
        {content.copy && (
          <MDXRenderer>{content.copy.childMdx.body}</MDXRenderer>
        )}
        {content.ctaLink && content.ctaTitle && (
          <Styled.a as={Link} to={content.ctaLink}>
            {content.ctaTitle} &rarr;
          </Styled.a>
        )}
      </Flex>
    </Box>
  )
}
